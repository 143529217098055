/* eslint-disable no-undef */
import React from "react";
import { buttonStyles } from "../../../css/buttons";
import useStoreInputs, {
  useStoreRoutingService,
  useStoreSteps,
  useStoreValuations,
  useTabsValuations,
} from "../../store/globalStates";

import {
  handleFetchReports,
  processResponses,
  upScroll,
  createValuationDate,
  newReportBack,
} from "../../helpers/utils";

interface FinishButtonProps {
  refFinish: React.RefObject<HTMLButtonElement>;
}

const FinishButton = ({ refFinish }: FinishButtonProps) => {
  const [isDDM, setIsDDM] = React.useState(false);
  const styles = buttonStyles();
  const { addValuation, removeSaveForLater, valuationSelected, valuationCounter } = useStoreValuations(
    (state) => state
  );
  const { inputs, valuationName, setShowValidation } = useStoreInputs((state) => state);
  const { stepNumber } = useStoreSteps((state) => state);
  const { setFinished, setSaveForLater } = useTabsValuations((state) => state);
  const { setRoutingNumber } = useStoreRoutingService((state) => state);
  const BASE_URL = "https://nonighter-backend-dev.conexa.ai";
  const validateInputs = () => {
    let isValidationReady = true;
    inputs.forEach((input) => {
      if (input.step === stepNumber && input.validation === false) {
        isValidationReady = false;
        setShowValidation(input.name, true);
      }
    });
    return isValidationReady;
  };

  const handleFinishValuation = async () => {
    if (!validateInputs()) {
      return;
    }

    setFinished(true);
    setSaveForLater(false);
    setRoutingNumber(3);
    removeSaveForLater(valuationSelected);

    const newValuation = {
      valuationName,
      inputs,
      date: createValuationDate(),
      valuationNumber: valuationCounter + 1,
      saveForLater: false,
    };
    for (const input of inputs) {
      if (["DDM"].includes(input.name) && input.value) {
        setIsDDM(true);
      }
    }
    const responses = await handleFetchReports(BASE_URL, valuationName, inputs, setRoutingNumber);
    if (!responses.length) return;

    const isProcessed = await processResponses(responses, newReportBack, setRoutingNumber, isDDM);
    if (!isProcessed) return;

    addValuation(newValuation);
    setRoutingNumber(4);
    upScroll();
  };

  return (
    <button
      tabIndex={-1}
      className={styles.buttonNext}
      style={{ borderRadius: "0.25rem" }}
      onClick={handleFinishValuation}
      ref={refFinish}
      id="button-finish"
    >
      Finish
    </button>
  );
};

export default FinishButton;
